import { api } from "@enerbit/base";

export const refustigateInverter = async (date: string, serials: string[]) => {
  const res = await api.post("/electricity-supply-service/device/inverter/refustigate-days", {
    date,
    meter_serials: serials,
  });

  return res;
};
