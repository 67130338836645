// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MuiTableRow-root th {
  background-color: #efe9fb !important;
  padding: 3px 7px 3px 7px !important;
}

.MuiPaper-root {
  box-shadow: none !important;
}

.MuiTableCell-root {
  border-bottom: unset !important;
  padding: 3px 7px 3px 7px !important;
}

.MuiInputBase-root {
  background-color: white;
  border-radius: 12px !important;
}
`, "",{"version":3,"sources":["webpack://./src/components/FustigationsTable/FustigationsTable.css"],"names":[],"mappings":"AAAA;EACE,oCAAoC;EACpC,mCAAmC;AACrC;;AAEA;EACE,2BAA2B;AAC7B;;AAEA;EACE,+BAA+B;EAC/B,mCAAmC;AACrC;;AAEA;EACE,uBAAuB;EACvB,8BAA8B;AAChC","sourcesContent":[".MuiTableRow-root th {\n  background-color: #efe9fb !important;\n  padding: 3px 7px 3px 7px !important;\n}\n\n.MuiPaper-root {\n  box-shadow: none !important;\n}\n\n.MuiTableCell-root {\n  border-bottom: unset !important;\n  padding: 3px 7px 3px 7px !important;\n}\n\n.MuiInputBase-root {\n  background-color: white;\n  border-radius: 12px !important;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
