export interface FustigationsState {
  fustigations: FailedFustigations[];
  selectedFustigations: FailedFustigations[];
  loading: boolean;
  totalPages: number;
  currentPage: number;
  error: boolean;
  searchValue: string;
  since: string;
  filter: SearchFilters;
}

export interface FustigationsResponse {
  items: FailedFustigations[];
  total: number;
  total_pages: number;
  netx_page: number;
}

export interface GetFustigationsFilters {
  since: string;
}

export interface Fustigations {
  since: string;
  until: string;
  id: string;
  measurement_point_id: string;
  judgment: boolean;
  serial: string;
  created_at: string;
}

export interface SearchFiltersModel {
  name: string;
  value: SearchFilters;
}

export enum SearchFilters {
  SERIAL = "serial",
  MEASUREMENT_POINT_ID = "measurement_point_id",
}

export interface FailedFustigations {
  since: string;
  until: string;
  measurement_point_id: string;
  serial: string;
  judgment_failed: boolean;
}
