import { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import {
  Box,
  Button,
  CloseIcon,
  CustomAlert,
  DatePicker,
  FormControl,
  LoadingButton,
  LocalizationProvider,
  Modal,
  Pagination,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@enerbit/base";
import moment from "moment";
import PaginationContainer from "../PaginationContainer/PaginationContainer";
import { refustigateInverter } from "../../services/fustigations";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

interface Props {
  open: boolean;
  onClose: () => void;
}

const ELEMENTS_PER_PAGE = 5;

const MassiveFustigation = ({ open, onClose }: Props) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);
  const [date, setDate] = useState<string>("");
  const [currentPage, setCurrentPage] = useState<number>(1);

  const selectedFustigations = useSelector(
    (state: RootState) => state.fustigations.selectedFustigations
  );

  const handlePagination = (event: React.ChangeEvent<unknown>, value: number) => {
    event.preventDefault();
    setCurrentPage(value);
  };

  const startIndex = useMemo(() => {
    return (currentPage - 1) * ELEMENTS_PER_PAGE;
  }, [currentPage]);

  const endIndex = useMemo(() => {
    return startIndex + ELEMENTS_PER_PAGE;
  }, [currentPage]);

  const onRefustigate = async () => {
    if (!date) return;
    setError(false);
    setLoading(true);

    try {
      const serials = selectedFustigations.map((item) => item.serial);
      await refustigateInverter(date, serials);
      setSuccess(true);
    } catch (error) {
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "550px",
    bgcolor: "background.paper",
    p: 3,
    borderRadius: "10px",
  };

  const handleClose = () => {
    setSuccess(false);
    setError(false);
    setDate("");
    onClose();
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={style}>
        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
          <Typography color={"primary"} sx={{ fontSize: "21px", fontWeight: 700 }}>
            Refustigar
          </Typography>
          <Button onClick={handleClose} variant='outlined' className='back-btn'>
            <CloseIcon />
          </Button>
        </Box>
        <Box my={2}>
          <Typography
            sx={{
              paddingBottom: "10px",
              fontWeight: 400,
              fontSize: "14px",
              lineHeight: "16px",
              color: "#3D4350",
            }}>
            Elige la fecha
          </Typography>
          <FormControl fullWidth>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                format='YYYY-MM-DD'
                value={date}
                // maxDate={moment.now()}
                disabled={loading}
                onChange={(value: any) => {
                  setDate(value.format("YYYY-MM-DD"));
                }}
                slotProps={{
                  textField: {
                    fullWidth: true,
                    error: false,
                    sx: {
                      "& fieldset": {
                        borderRadius: "12px",
                      },
                    },
                  },
                }}
              />
            </LocalizationProvider>
          </FormControl>
        </Box>
        {error && (
          <Box mb={1}>
            <CustomAlert
              severity='error'
              text={"Hubo un error enviando el comando de refustigación."}
              onClose={() => setError(false)}
            />
          </Box>
        )}
        {success && (
          <Box mb={1}>
            <CustomAlert
              severity='success'
              text={"El comando de refustigación ha sido enviado."}
              onClose={() => setSuccess(false)}
            />
          </Box>
        )}
        <LoadingButton
          fullWidth
          variant='contained'
          color='primary'
          loading={loading}
          disabled={selectedFustigations.length === 0 || loading || !date}
          onClick={onRefustigate}>
          Refustigar
        </LoadingButton>
        <Box my={2}>
          <Typography sx={{ color: "#53358E", fontSize: "18px" }}>
            Lista de medidores seleccionados ({selectedFustigations.length})
          </Typography>
          <TableContainer component={Paper} sx={{ mt: 2 }}>
            <Table>
              <TableHead sx={{ ".MuiTableCell-root": { padding: "12px !important" } }}>
                <TableRow>
                  <TableCell>Frontera</TableCell>
                  <TableCell>Serial del medidor</TableCell>
                </TableRow>
              </TableHead>
              <TableBody sx={{ ".MuiTableCell-root": { padding: "12px !important" } }}>
                {selectedFustigations.slice(startIndex, endIndex).map((frt, index) => (
                  <TableRow key={`${index + 1}-${frt.measurement_point_id}`}>
                    <TableCell>{frt.serial}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <PaginationContainer>
            <Stack spacing={2}>
              <Pagination
                page={currentPage}
                count={Math.round(selectedFustigations.length / ELEMENTS_PER_PAGE)}
                shape='rounded'
                onChange={handlePagination}
              />
            </Stack>
          </PaginationContainer>
        </Box>
      </Box>
    </Modal>
  );
};

export default MassiveFustigation;
